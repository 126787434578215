<template>
	<div class="opacity-page">
		<div class="settings-content settings-notifications">
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content" v-if="loaded">
					<div class="notifications-list">
						<div class="notification-row level-1" v-bind:class="{active: selectedNotifications.some(element => proceduresNotifications.includes(element))}" @click="selectAllProcedures">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t('settings-notifications.procedures') }}</span>
							</div>
						</div>
						<div class="notification-row level-2" v-for="notification in proceduresNotifications" v-bind:class="{active: selectedNotifications.includes(notification)}" @click="toggleSelected(notification)">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>

						<div class="notification-row level-1" v-bind:class="{active: selectedNotifications.some(element => processesNotifications.includes(element))}" @click="selectAllProcesses">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t('settings-notifications.processes') }}</span>
							</div>
						</div>
						<div class="notification-row level-2" v-for="notification in processesNotifications" v-bind:class="{active: selectedNotifications.includes(notification)}" @click="toggleSelected(notification)">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>
					</div>

					<div class="save-modifications">
						<button id="buttonUpdate" class="btn-tbf blue center" @click="updateNotifications">
							<div class="loader"></div>
							<span class="text">{{$t('general.update')}}</span>
						</button>
					</div>
				</div>

				<div class="content" v-else>
					<div class="placeholder-loader" style="height: 54px; min-width: 100%; width: 100%; margin-top: 30px;"></div>
					<div class="placeholder-loader" style="height: 54px; max-width: 100%; width: 100%; margin-top: 15px;"></div>
					<div class="placeholder-loader" style="height: 54px; max-width: 100%; width: 100%; margin-top: 15px;"></div>
					<div class="placeholder-loader" style="height: 54px; max-width: 100%; width: 100%; margin-top: 15px;"></div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		components: {

		},
		data() {
			return {
				loaded: false,
				selectedNotifications: [],
				proceduresNotifications: ['new_procedure', 'assigned_to_procedure', 'edit_live_procedure', 'change_to_draft_procedure', 'change_to_live_procedure', 'delete_procedure', 'add_improvement_proposal_to_procedure', 'change_status_improvement_proposal', 'create_admin_procedures'],
				processesNotifications: ['new_version_process', 'assigned_to_process', 'edit_live_process', 'add_improvement_proposal_to_process', 'delete_process', 'create_admin_processes']
			};
		},
		async mounted(){
			this.getNotifications()

			setTimeout(() => {
				$('.opacity-page').addClass('show')
				var title = this.$t('settings-navbar.notifications');
				this.$root.$emit("navbar_title", title);
			}, 0)
		},
		methods: {
			getNotifications(){
				axios.get('/mail-notifications')
				.then(({data}) => {
					this.selectedNotifications = data.data
				})
				.finally(() => {
					this.loaded = true
				})
			},
			toggleSelected(type){
				if(this.selectedNotifications.includes(type)){
					this.selectedNotifications.splice(this.selectedNotifications.indexOf(type), 1)
				}else{
					this.selectedNotifications.push(type)
				}
			},
			selectAllProcedures(){
				if(this.selectedNotifications.some(element => this.proceduresNotifications.includes(element))){
					this.proceduresNotifications.map(el => {
						if(this.selectedNotifications.includes(el)){
							this.selectedNotifications.splice(this.selectedNotifications.indexOf(el), 1)
						}
					})
				}else{
					this.proceduresNotifications.map(el => {
						this.selectedNotifications.push(el)
					})
				}
			},
			selectAllProcesses(){
				if(this.selectedNotifications.some(element => this.processesNotifications.includes(element))){
					this.processesNotifications.map(el => {
						if(this.selectedNotifications.includes(el)){
							this.selectedNotifications.splice(this.selectedNotifications.indexOf(el), 1)
						}
					})
				}else{
					this.processesNotifications.map(el => {
						this.selectedNotifications.push(el)
					})
				}
			},
			updateNotifications(){
				var buttonName = 'buttonUpdate'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.patch('/mail-notifications', {mail_notifications: this.selectedNotifications})
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
			}
		}
	};
</script>

<style lang="scss">
    @import "../../../styles/pages/settings_notifications.scss";
</style>